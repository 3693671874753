import debounce from 'es6-promise-debounce';

import ACTIONS from '../../actions';
import Api from '../../api';

import AeroexpressValidator from '../aeroexpress/validator/aeroexpress';
import TripValidator from './validator/trip';
import CustomValidator from './validator/custom';
import EventValidator from './validator/event';
import HotelValidator from './validator/hotel';
import TransferValidator from './validator/transfer';
import TrainValidator from './validator/train';
import VipHallValidator from './validator/vipHall';
import TaxiValidator from './validator/taxi';
import GiftVoucherValidator from './validator/giftVoucher';
import InsuranceValidator from './validator/insurance';
import BusValidator from './validator/bus';
import PriceValidator from './validator/price';

import {
  getDefaultMoment,
  momentHours,
  momentMinutes,
  momentSetHoursAndMinutes,
  formatDate,
  momentObject,
  getMoment,
  subDate,
} from '../../utils/formatDate';
import lodashReplaces from '../../../bi/utils/lodashReplaces';
import {
  makeTrainDatesToMoment, normalizeDate, normalizeNumber, prepareDatesInsurance,
} from '../../utils/trip';
import { alertDev } from '../../../utils/alert';
import { getRegionId, getSumVatDetails } from '../../utils/hotels';

import { SERVICETYPE } from '../../constants/serviceType';
import { TYPE_OF_STATION } from '../../constants/bus';
import {
  HOTEL_PROVIDER_VALUE,
  HOTELPROVIDERNAME as HOTEL_PROVER_NAME_LABELS,
} from '../../constants/hotel';
import { LOCALE, MEAL_LIST_TYPE } from '../../constants/hotels';
import {
  FIELDS,
  FLIGHT_CERTIFICATE_STATUS_TYPES,
  ROUTE,
  TRAIN_PRICING,
  DIALOG_RESULT_MSG,
  STATUSES_ERROR_CURRENCY,
  VALIDATE_FIELDS_GIFT_VOUCHER,
  TRIPSLABELS,
  FIELDS_GIFT_VOUCHER,
} from '../../constants/trips';
import { DEFAULT_SW_COMISSION } from '../../../constants/rateTypes';
import { FULLTIME } from '../../../constants/time';
import { LOADING_STATUS } from '../../stores/trip';

let store = null;
let trainStore = null;

const LABELS = { NOT_FOUND: 'NotFound' };

const ORDERTYPE = { ...SERVICETYPE };

const ORDER_PRICE = {
  HOTEL: {
    Base: 0,
    commission: 0,
    hasVat: '',
    EarlyCheckIn: 0,
    LateCheckOut: 0,
  },
  CUSTOM: {
    Base: 0,
    commission: 0,
    hasVat: false,
    VAT: 0,
  },
  EVENT: {
    Base: 0,
    commission: 0,
    Commission: 0,
    hasVat: false,
    VAT: 0,
  },
  TRANSFER: {
    Base: 0,
    Fee: 0,
    commission: 0,
    hasVat: false,
  },
  VIP_HALL: {
    Base: 0,
    commission: 0,
    hasVat: false,
    VAT: 0,
  },
  AEROEXPRESS: {
    Base: 0,
    Fee: 0,
    commission: 0,
  },
  INSURANCE: {
    Base: 0,
    Commission: 0,
  },
  BUS: {
    Base: 0,
    commission: 0,
    hasVat: false,
    VAT: 0,
  },
};

const INTERNAL_VAT = {
  HOTEL: {
    VatReady: null,
    VatInfo: null,
  },
};

const getHotelDefaultData = (provider) => ({
  CheckinDate: '',
  CheckoutDate: '',
  NightsCount: 0,
  NightsPrice: 0,
  EmployeeIds: null,
  GuestCount: 0,
  Hotel: {
    Address: '',
    CheckinTime: '',
    CheckoutTime: '',
    City: '',
    Name: '',
    Phone: '',
    Stars: 0,
  },
  Price: 0,
  ReservationDate: '',
  ReservationNumber: null,
  Room: {
    Category: '',
    FreeCancellation: null,
  },
  ProviderName: provider || HOTEL_PROVIDER_VALUE.ostrovok,
  Breakfast: false,
  BreakfastName: null,
  ServiceType: SERVICETYPE.HOTEL,
});

const APARTMENTDEFAULTDATA = {
  CheckinDate: '',
  CheckoutDate: '',
  EmployeeIds: null,
  GuestCount: 0,
  Appartment: {
    Address: '',
    CheckinTime: '',
    CheckoutTime: '',
    City: '',
    Name: '',
    Phone: '',
    Stars: 0,
  },
  Price: 0,
  ReservationDate: '',
  ReservationNumber: null,
  Room: {
    Category: '',
    FreeCancellation: null,
  },
  ServiceType: 'Apartment',
};

const TRANSFERDEFAULTDATA = {
  ArrivalPoint: '',
  CarClass: 'Standart',
  CarModel: '',
  CheckinDate: '',
  CheckinTime: '',
  CheckoutDate: '',
  CheckoutTime: '',
  CancellationDate: '',
  CancellationTime: '',
  City: '',
  DeparturePoint: '',
  EmployeeIds: [],
  ServiceType: 'Transfer',
  ProviderOrderId: '',
  PartnerOrderId: '',
  Capacity: 0,
  Note: '',
  TableSignature: '',
  DateDeparture: '',
  LocationAddress: '',
  ArrivalNumber: '',
  DestinationAddress: '',
  DepartureNumber: '',
  StartPlace: {
    Id: 0,
    TransferPlaceType: '',
    Name: '',
    Country: '',
    City: '',
    Place: '',
    IATA: '',
  },
  EndPlace: {
    Id: 0,
    TransferPlaceType: '',
    Name: '',
    Country: '',
    City: '',
    Place: '',
    IATA: '',
  },
  Price: 0,
  ProviderName: '',
};

const CUSTOMDEFAULTDATA = {
  Name: '',
  CheckinDate: '',
  CheckoutDate: '',
  CheckinTime: '',
  CheckoutTime: '',
  EmployeeIds: [],
  ServiceType: 'Custom',
  OrderId: '',
  ProviderName: '',
  Type: '',
  VoucherFileName: '',
  Hotel: null,
};

const EVENT_DEFAULT_DATA = {
  ServiceType: 'MICE',
  ProviderName: 'Alvist',
  Name: '',
  CheckinDate: '',
  CheckoutDate: '',
  VoucherFileName: '',
};

const VIP_HALL_DEFAULT_DATA = {
  ProviderOrderId: '',
  ProviderName: '',
  EmployeeIds: [],
  ServiceType: 'VipHall',
  City: '',
  ReservationDate: getDefaultMoment(),
  CheckinDate: '',
  CheckoutDate: '',
  CheckinTime: '',
  CheckoutTime: '',
  CancellationDate: '',
  CancellationTime: 0,
  Name: '',
  TripType: 'Domestic',
  Flight: [
    {
      Number: '',
      Date: getDefaultMoment(),
      Type: 'Departure',
      CheckinDate: '',
      CheckoutDate: '',
      DepartureAirport: {
        Code: '',
        Name: '',
        Terminal: '',
      },
      ArrivalAirport: {
        Code: '',
        Name: '',
        Terminal: '',
      },
    },
  ],
  AdditionalInfo: '',
  AdditionalServices: [],
  Comment: '',
};

const BUS_DEFAULT_DATA = {
  ServiceType: 'Bus',
  ProviderName: '',
  Departure: '',
  Destination: '',
  SeatNumber: '',
  CheckinDate: '',
  CheckoutDate: '',
  EmployeeIds: [],
  ChatId: 0,
  busRoute: {
    from: {
      Departure: '', suggest: [], typeOfPoint: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE,
    },
    to: {
      Destination: '', suggest: [], typeOfPoint: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE,
    },
  },
  VoucherFileName: '',
  HasLuggage: true,
};

const BUS_DEFAULT_RATE = [{ AccountId: 0, RateType: DEFAULT_SW_COMISSION }];

const NEW_TRIP_DEFAULT_DATA = {
  CompanyId: 0,
  Name: '',
  Users: '',
  Versions: [],
};

const HOTELPROVIDERNAME = [
  { label: HOTEL_PROVER_NAME_LABELS.ostrovok, value: HOTEL_PROVIDER_VALUE.ostrovok },
  { label: HOTEL_PROVER_NAME_LABELS['bronevik-online'], value: HOTEL_PROVIDER_VALUE['bronevik-online'] },
  { label: HOTEL_PROVER_NAME_LABELS.academ, value: HOTEL_PROVIDER_VALUE.academ },
  { label: HOTEL_PROVER_NAME_LABELS.expedia, value: HOTEL_PROVIDER_VALUE.expedia },
  { label: HOTEL_PROVER_NAME_LABELS.tripcom, value: HOTEL_PROVIDER_VALUE.tripcom },
  { label: HOTEL_PROVER_NAME_LABELS.direct, value: HOTEL_PROVIDER_VALUE.direct },
  { label: HOTEL_PROVER_NAME_LABELS.aanda, value: HOTEL_PROVIDER_VALUE.aanda },
  { label: HOTEL_PROVER_NAME_LABELS.hotelbook, value: HOTEL_PROVIDER_VALUE.hotelbook },
  { label: HOTEL_PROVER_NAME_LABELS.booking, value: HOTEL_PROVIDER_VALUE.booking },
  { label: HOTEL_PROVER_NAME_LABELS.travelline, value: HOTEL_PROVIDER_VALUE.travelline },
  { label: HOTEL_PROVER_NAME_LABELS.verdeho3d, value: HOTEL_PROVIDER_VALUE.verdeho3d },
  { label: HOTEL_PROVER_NAME_LABELS.verdeho, value: HOTEL_PROVIDER_VALUE.verdeho },
  { label: HOTEL_PROVER_NAME_LABELS.satguru, value: HOTEL_PROVIDER_VALUE.satguru },
  { label: HOTEL_PROVER_NAME_LABELS.alvist, value: HOTEL_PROVIDER_VALUE.alvist },
  { label: HOTEL_PROVER_NAME_LABELS.goGlobalTravel, value: HOTEL_PROVIDER_VALUE.goGlobalTravel },
  { label: HOTEL_PROVER_NAME_LABELS.rcb, value: HOTEL_PROVIDER_VALUE.rcb },
  { label: HOTEL_PROVER_NAME_LABELS.verdehoAgent, value: HOTEL_PROVIDER_VALUE.verdehoAgent },
];

const TRANSFERCARCLASS = [
  { label: 'Стандарт', value: 'Standart' },
  { label: 'Стандарт (по платной дороге)', value: 'TollRoadStandart' },
  { label: 'Комфорт', value: 'Comfort' },
  { label: 'Комфорт (по платной дороге)', value: 'TollRoadComfort' },
  { label: 'Бизнес лайт', value: 'BusinessLite' },
  { label: 'Бизнес', value: 'Business' },
  { label: 'Бизнес (по платной дороге)', value: 'TollRoadBusiness' },
  { label: 'Минивен', value: 'Minivan' },
  { label: 'Минивен VIP', value: 'MinivanVip' },
  { label: 'Микроавтобус', value: 'MiniBus' },
  { label: 'Микроавтобус, 18 мест', value: 'LargeMiniBus' },
  { label: 'Автобус', value: 'Bus' },
  { label: 'Представительский', value: 'Executive' },
];

const DEBOUNCE_TIME = 200;
const autocompleteDebounce = debounce(Api.Trip.autocomplete, DEBOUNCE_TIME);
const autocompleteHotelDebounce = debounce(Api.Trip.autocompleteHotel, DEBOUNCE_TIME);
const autocompleteCompanyDebounce = debounce(Api.Trip.autocompleteCompany, DEBOUNCE_TIME);
const autocompleteTrainStationDebounce = debounce(Api.Trip.autocompleteTrainStation, DEBOUNCE_TIME);
const autocompleteBusStationDebounce = debounce(Api.Trip.autocompleteBusStation, DEBOUNCE_TIME);
const autocompleteEmployeesDebounce = debounce(Api.Trip.autocompleteEmployees, DEBOUNCE_TIME);
const autocompleteProvidersDebounce = debounce(Api.Trip.autocompleteProvider, DEBOUNCE_TIME);
const autocompleteTripToMergeDebounce = debounce(Api.Trip.getTripInfoById, DEBOUNCE_TIME);

class TripService {
  constructor(InitStore, TrainStore) {
    store = InitStore || this.getStore('Trip');
    this.store = store;
    trainStore = TrainStore || this.getStore('TripTrain');

    this.validator = {
      event: new EventValidator(),
      custom: new CustomValidator(),
      hotel: new HotelValidator(),
      transfer: new TransferValidator(),
      tripName: new TripValidator(),
      train: new TrainValidator(),
      vipHall: new VipHallValidator(),
      taxi: new TaxiValidator(),
      giftVoucher: new GiftVoucherValidator(),
      aeroexpress: new AeroexpressValidator(),
      insurance: new InsuranceValidator(),
      bus: new BusValidator(),
      price: new PriceValidator(),
    };
  }

  getTrainData = () => trainStore.getState();

  changeField = (value, path) => {
    const currentState = trainStore.getState();
    const updatedState = lodashReplaces.setWith(currentState, path, value);

    trainStore.dispatch({
      type: ACTIONS.TRIP_TRAIN.CHANGE_FIELD,
      updatedState,
    });
  };

  changeFields = (paths) => {
    const currentState = trainStore.getState();
    const updatedState = paths.reduce((acc, { value, path }) => lodashReplaces.setWith(acc, path, value), currentState);

    trainStore.dispatch({
      type: ACTIONS.TRIP_TRAIN.CHANGE_FIELD,
      updatedState,
    });
  };

  subscribeTrain = (callback) => trainStore.subscribe(callback);

  autocompleteTrainStation = async (value) => {
    try {
      return await autocompleteTrainStationDebounce({ query: value });
    } catch (e) {
      return [];
    }
  };

  trainTripFieldsValidation = (
    fields, mandatoryProject, projects, isPartEdit,
  ) => this.validator.train.getValidation(fields, mandatoryProject, projects, isPartEdit);

  normalizeTrainJson = (JsonData, partEditItem) => {
    const {
      Route: {
        DateArrive,
        TimeArrive,
        DateArriveLocal,
        TimeArriveLocal,
        DateDepartureLocal,
        TimeDepartureLocal,
        DateDeparture,
        TimeDeparture,
        PlaceNumber,
      },
      TrainPricing: {
        Fare,
        TariffPrice,
      },
    } = JsonData;
    let dateArriveMoment = DateArrive;
    let dateDepartureMoment = DateDeparture;
    let dateArriveLocalMoment = DateArriveLocal;
    let dateDepartureLocalMoment = DateDepartureLocal;

    if (!partEditItem) {
      dateArriveMoment = momentSetHoursAndMinutes(DateArrive, momentHours(TimeArrive), momentMinutes(TimeArrive));
      dateDepartureMoment = momentSetHoursAndMinutes(
        DateDeparture, momentHours(TimeDeparture), momentMinutes(TimeDeparture),
      );
      dateArriveLocalMoment = momentSetHoursAndMinutes(
        DateArriveLocal, momentHours(TimeArriveLocal), momentMinutes(TimeArriveLocal),
      );
      dateDepartureLocalMoment = momentSetHoursAndMinutes(
        DateDepartureLocal, momentHours(TimeDepartureLocal), momentMinutes(TimeDepartureLocal),
      );
    }

    const paths = [
      { value: normalizeDate(dateDepartureLocalMoment), path: ROUTE.DATE_DEPARTURE_LOCAL },
      { value: normalizeDate(dateArriveLocalMoment), path: ROUTE.DATE_ARRIVE_LOCAL },
      { value: normalizeDate(dateDepartureMoment), path: ROUTE.DATE_DEPARTURE },
      { value: normalizeDate(dateArriveMoment), path: ROUTE.DATE_ARRIVE },
      { value: PlaceNumber || 'XXX', path: ROUTE.PLACE_NUMBER },
      { value: normalizeNumber(Fare), path: TRAIN_PRICING.FARE },
      { value: normalizeNumber(TariffPrice), path: TRAIN_PRICING.TARIFF_PRICE },
    ];

    return paths.reduce((acc, { value, path }) => lodashReplaces.setWith(acc, path, value), JsonData);
  };

  updateFileWithoutReq = (guid, file) => {
    trainStore.dispatch({
      type: ACTIONS.TRIP_TRAIN.UPLOAD_FILE,
      guid,
      file,
    });
  };

  uploadFile = async (file) => {
    try {
      const guid = await Api.Trip.sendTrainVoucher(file);
      trainStore.dispatch({
        type: ACTIONS.TRIP_TRAIN.UPLOAD_FILE,
        guid,
        file,
      });
    } catch (error) {
      trainStore.dispatch({ type: ACTIONS.TRIP_TRAIN.UPLOAD_FILE_FAIL });
    }
  };

  addVouchers = (vouchers) => {
    store.dispatch({
      type: ACTIONS.TRIP.ADD_VOUCHERS,
      vouchers,
    });
  };

  addBusVouchers = (vouchers) => {
    store.dispatch({
      type: ACTIONS.TRIP.ADD_BUS_VOUCHERS,
      vouchers,
    });
  };

  addNewVoucher = () => {
    store.dispatch({ type: ACTIONS.TRIP.ADD_CUSTOM_VOUCHER });
  };

  addEventVoucher = () => store.dispatch({ type: ACTIONS.TRIP.ADD_EVENT_VOUCHER });

  addBusVoucher = () => store.dispatch({ type: ACTIONS.TRIP.ADD_BUS_VOUCHER });

  uploadCustomFile = async (file, ind) => {
    store.dispatch({
      type: ACTIONS.TRIP.UPLOAD_CUSTOM_VOUCHER,
      payload: {
        file,
        ind,
      },
    });
  };

  uploadEventFile = (file, ind) => {
    store.dispatch({
      type: ACTIONS.TRIP.UPLOAD_EVENT_VOUCHER,
      payload: {
        file,
        ind,
      },
    });
  };

  uploadBusFile = async (file, ind) => {
    store.dispatch({
      type: ACTIONS.TRIP.UPLOAD_BUS_VOUCHER,
      payload: {
        file,
        ind,
      },
    });
  };

  uploadCustomFiles = async (files) => {
    try {
      const { FileId } = await Api.Trip.sendCustomVoucher(files);

      return FileId;
    } catch (error) {
      store.dispatch({ type: ACTIONS.TRIP.FILED_CUSTOM_VOUCHER });
    }

    return '';
  };

  uploadEventFiles = async (files) => {
    try {
      const { FileId } = await Api.Trip.sendCustomVoucher(files);

      return FileId;
    } catch (error) {
      store.dispatch({ type: ACTIONS.TRIP.FILED_EVENT_VOUCHER });

      return '';
    }
  };

  uploadBusFiles = async (files) => {
    try {
      const { FileId } = await Api.Trip.sendBusVoucher(files);

      return FileId;
    } catch (error) {
      store.dispatch({ type: ACTIONS.TRIP.FILED_BUS_VOUCHER });
    }

    return '';
  };

  uploadAirAdditionalFiles = async (files) => {
    try {
      return Api.Trip.sendAirAdditionalVoucher(files);
    } catch (error) {
      store.dispatch({ type: ACTIONS.TRIP.FILED_CUSTOM_VOUCHER });
    }

    return '';
  };

  resetCustomFile = (ind) => store.dispatch({
    type: ACTIONS.TRIP.RESET_CUSTOM_VOUCHER,
    payload: { ind },
  });

  resetEventFile = (ind) => store.dispatch({
    type: ACTIONS.TRIP.RESET_EVENT_VOUCHER,
    payload: { ind },
  });

  resetBusFile = (ind) => store.dispatch({
    type: ACTIONS.TRIP.RESET_BUS_VOUCHER,
    payload: { ind },
  });

  defaultCustomFile = () => {
    store.dispatch({ type: ACTIONS.TRIP.DEFAULT_CUSTOM_FILE });
    this.resetErrorAero();
  };

  defaultEventFile = () => store.dispatch({ type: ACTIONS.TRIP.DEFAULT_EVENT_FILE });

  defaultBusFile = () => store.dispatch({ type: ACTIONS.TRIP.DEFAULT_BUS_FILE });

  resetUploadedFile = () => trainStore.dispatch({ type: ACTIONS.TRIP_TRAIN.UPLOAD_FILE_RESET });

  recalculateTripAmount = async (providerName, orderId, payload) => {
    try {
      return await Api.Trip.recalculateTripAmount(providerName, orderId, payload);
    } catch (e) {
      return null;
    }
  };

  getRefundSumAirlineFail = () => {
    store.dispatch({
      type: ACTIONS.TRIP.LOADSUMAIRLINEFAIL,
      success: false,
    });
  };

  updateStateTicketStatus = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.UPDATETICKETSTATUS,
      ticketStatus: value,
    });
  };

  ticketStatusFail = () => {
    store.dispatch({
      type: ACTIONS.TRIP.TICKETSTATUSEFAIL,
      error: true,
    });
  };

  setCancellationAirlineFeeFail = () => {
    store.dispatch({
      type: ACTIONS.TRIP.SETSUMAIRLINEFAIL,
      successRefund: false,
    });
  };

  setCancellationAeroExpressFail = () => {
    store.dispatch({
      type: ACTIONS.TRIP.SETAEROEXPRESSFAIL,
      aeroExpressFail: true,
    });
  };

  getCancelTripInfoFail = () => {
    trainStore.dispatch({ type: ACTIONS.TRIP_TRAIN.GET_CANCEL_TRIP_INFO_FAIL });
    this.updateCancelLoading(false);
  };

  getTrainCarriers = async () => {
    try {
      const res = await Api.Trip.getTrainCarriers();
      const carriers = res.map((carrier, i) => ({
        ...carrier, name: carrier.FullName, id: i,
      }));

      trainStore.dispatch({
        type: ACTIONS.TRIP_TRAIN.GET_TRAIN_CARRIERS,
        carriers,
      });
    } catch (error) {
      trainStore.dispatch({
        type: ACTIONS.TRIP_TRAIN.GET_TRAIN_CARRIERS_FAIL,
        carriers: [],
      });
    }
  };

  resetError = () => trainStore.dispatch({ type: ACTIONS.TRIP_TRAIN.RESET_ERROR });

  resetTrainStore = () => trainStore.dispatch({ type: ACTIONS.TRIP_TRAIN.RESET_TRAIN_STORE });

  load(companyId, tripId) {
    return Api.Trip.getOrder(companyId, tripId).then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.UPDATE,
        trip: res,
      });
    });
  }

  getPrevVersions = (versions, currentVersion) => {
    const sortItems = [...versions].sort((prev, next) => {
      if (prev.Id < next.Id) return -1;
      if (prev.Id > next.Id) return 1;

      return 0;
    });

    const idx = sortItems.findIndex((el) => el.Id === currentVersion.Id);

    return sortItems.slice(0, idx);
  };

  checkAllVersionsVatReady = (currentVersion) => {
    const { currentTrip: { OrderItems } } = store.getState();

    const currentOrder = OrderItems.find(({ Id }) => Id === currentVersion.TripItemId);

    const prevVersions = this.getPrevVersions(currentOrder.Versions, currentVersion);

    return prevVersions.every(({ InternalVat: { VatReady } }) => VatReady);
  };

  getSumVatAmount = (currentVersion) => {
    const { currentTrip: { OrderItems } } = store.getState();
    const currentOrder = OrderItems.find(({ Id }) => Id === currentVersion.TripItemId);

    return currentOrder.Versions.reduce((sum, { InternalVat: { VatInfo: { VatDetails, HasVat } } }) => {
      if (HasVat) {
        return sum + getSumVatDetails(VatDetails);
      }

      return sum;
    }, 0);
  };

  getMaxRate = (currentVersion) => {
    const { currentTrip: { OrderItems } } = store.getState();
    const currentOrder = OrderItems.find(({ Id }) => Id === currentVersion.TripItemId);

    const prevVatDetails = currentOrder.Versions
      .filter(({ InternalVat: { VatReady, VatInfo } }) => VatReady && VatInfo.HasVat)
      .flatMap(({ InternalVat: { VatInfo: { VatDetails } } }) => VatDetails);

    if (!prevVatDetails.length) return 0;

    return Math.max(...prevVatDetails.map((item) => item.Rate));
  };

  getDisplayBus = async (accountId) => {
    try {
      const busChangeRate = await Api.Trip.loadBusChangeRate(accountId);
      this.loadChangeRateBus(busChangeRate);
    } catch (error) {
      this.loadChangeRateBus(BUS_DEFAULT_RATE);
    }
  };

  getUsers = async (companyId) => {
    const users = await Api.Trip.getUsers(companyId);

    this.loadUserByCompanyId(users);

    return users;
  };

  loadAll = async (accountId, companyId, tripId) => {
    const getTripComments = !tripId ? () => {} : Api.Trip.getTripComments(tripId);

    await this.getDisplayBus(accountId);

    return Promise.all([
      Api.Trip.getCompanies(accountId),
      Api.Trip.getProjects(accountId),
      Api.Trip.getUsers(companyId),
      Api.Trip.loadAirlineChangeRate(accountId),
      Api.Trip.loadTrainChangeRate(accountId),
      Api.Trip.loadIntegrationVersionsForAllCompanies(tripId),
      Api.CustomAnalytics.getAnalyticsWithoutValues(accountId),
      getTripComments,
    ]).then((res) => {
      this.loadCompaniesByAccountId(res[0]);
      this.loadProjectsByAccountId(res[1]);
      this.loadUserByCompanyId(res[2]);
      this.loadChangeRateAir(res[3]);
      this.loadChangeRateTrain(res[4]);
      this.loadIntegration(res[5], tripId);
      this.loadAnalyticsByCompanyId(res[6]);
      this.loadTripComments(res[7] || []);
      this.updateLoading(false);
    });
  };

  createNewTrip() {
    store.dispatch({
      type: ACTIONS.TRIP.UPDATE,
      trip: NEW_TRIP_DEFAULT_DATA,
    });
  }

  clearStore = () => store.dispatch({ type: ACTIONS.TRIP.CLEARSTORE });

  loadChangeRateAir = (airline) => store.dispatch({
    type: ACTIONS.TRIP.LOADCHANGECOMMISSIONAIR,
    payload: airline,
  });

  loadChangeRateTrain = (train) => store.dispatch({
    type: ACTIONS.TRIP.LOADCHANGECOMMISSIONTRAIN,
    payload: train,
  });

  loadChangeRateBus = (bus) => store.dispatch({
    type: ACTIONS.TRIP.LOAD_CHANGE_COMMISSION_BUS,
    payload: bus[0],
  });

  loadIntegrationVersionsForAllCompanies = (tripId) => Api.Trip.loadIntegrationVersionsForAllCompanies(tripId)
    .then((versions) => {
      store.dispatch({
        type: ACTIONS.TRIP.LOADINTEGRATIONVAERSIONS,
        payload: versions,
      });
    });

  loadIntegration = (res, tripId) => {
    store.dispatch({
      type: ACTIONS.TRIP.LOADINTEGRATION,
      payload: !!res,
    });

    if (res && tripId) {
      this.loadIntegrationVersionsForAllCompanies(tripId);
    }
  };

  updateLoading = (value) => store.dispatch({
    type: ACTIONS.TRIP.UPDATELOADING,
    payload: value,
  });

  updateDialogLoading = (value) => store.dispatch({
    type: ACTIONS.TRIP.UPDATE_DIALOG_LOADING,
    payload: value,
  });

  updateMergeLoading = (value) => store.dispatch({
    type: ACTIONS.TRIP.SET_MERGING_LOADING,
    payload: value,
  });

  sendVersionOfIntegration = (tripId, id, uploaded) => Api.Trip.sendVersionOfIntegration({
    TripItemVersionId: id,
    Uploaded: uploaded,
  }).then(() => {
    this.loadIntegrationVersionsForAllCompanies(tripId);
  })
    .catch(() => this.loadIntegrationVersionsForAllCompanies(tripId));

  downloadVersionOfIntegration = (guid) => Api.Trip.downloadVersionOfIntegration(guid);

  loadCompaniesByAccountId = (res) => store.dispatch({
    type: ACTIONS.TRIP.LOADCOMPANIES,
    companies: res,
  });

  loadProjectsByAccountId = (res) => store.dispatch({
    type: ACTIONS.TRIP.LOADPROJECTS,
    projects: res,
  });

  loadMandatoryProject = (accountId) => Api.Trip.getMandatoryProject(accountId).then((res) => store.dispatch({
    type: ACTIONS.TRIP.LOADMANDATORYROJECT,
    mandatoryProject: res,
  }));

  loadUserByCompanyId = (res) => store.dispatch({
    type: ACTIONS.TRIP.lOADUSER,
    users: res,
  });

  loadAnalyticsByCompanyId = (res) => store.dispatch({
    type: ACTIONS.TRIP.LOADANALYTICS,
    analytics: res,
  });

  loadTripComments = (list) => store.dispatch({
    type: ACTIONS.TRIP.UPDATE_TRIP_COMMENTS,
    payload: list,
  });

  updateVersionButtons = (id) => store.dispatch({
    type: ACTIONS.TRIP.UPDATEVERSIONBUTTONS,
    payload: id,
  });

  saveOperationAirline = (value) => store.dispatch({
    type: ACTIONS.TRIP.SAVE_OPERATION_AIRLINE,
    payload: value,
  });

  startEdit(companyId, orderId) {
    return Api.Trip.startEdit(companyId, orderId).then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.STARTEDIT,
        status: res,
      });
    });
  }

  cancelEdit(companyId, orderId) {
    return Api.Trip.cancelEdit(companyId, orderId).then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.CANCELEDIT,
        status: res,
      });
    });
  }

  setLegalDialog = (isOpen) => store.dispatch({
    type: ACTIONS.TRIP.SET_LEGAL_DIALOG,
    payload: isOpen,
  });

  getStatusEditLegalEntity = async (tripItemId) => {
    try {
      const response = await Api.Trip.getStatusEditLegalEntity(tripItemId);

      store.dispatch({
        type: ACTIONS.TRIP.GET_LEGAL_STATUS,
        payload: response,
      });
    } catch (err) {
      store.dispatch({
        type: ACTIONS.TRIP.GET_LEGAL_STATUS,
        payload: false,
      });
    }
  };

  sendLegalEntity = async (data) => {
    this.setWaitPreloader(true);

    try {
      await Api.Trip.sendLegalEntity(data);

      this.setWaitPreloader(false);
      this.setLegalDialog(false);
    } catch (err) {
      store.dispatch({ type: ACTIONS.TRIP.SET_ERROR_LEGAL_ENTITY });
      this.setWaitPreloader(false);
    }
  };

  setLegalValue = (value, tripItemId) => store.dispatch({
    type: ACTIONS.TRIP.SET_LEGAL_VALUE,
    payload: {
      value,
      tripItemId,
    },
  });

  getLegalEntityList = async (tripItemId) => {
    try {
      const list = await Api.Trip.getLegalEntityList();

      store.dispatch({
        type: ACTIONS.TRIP.SET_LEGAL_LIST,
        payload: list,
      });

      this.setLegalValue(list, tripItemId);
    } catch (err) {
      store.dispatch({ type: ACTIONS.TRIP.SET_ERROR_LEGAL_ENTITY });
    }
  };

  resetErrorAero = () => store.dispatch({ type: ACTIONS.TRIP.RESET_ERROR_AERO });

  updateTrip(companyId, tripId) {
    return Api.Trip.getOrder(companyId, tripId).then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.REFRESHTRIP,
        trip: res,
      });

      return res;
    });
  }

  updateCancelLoading = (value) => {
    trainStore.dispatch({
      type: ACTIONS.TRIP_TRAIN.UPDATE_CANCEL_LOADING,
      payload: value,
    });
  };

  getCancellationFee(companyId, tripItemId) {
    return Api.Trip.getCancellationFee(companyId, tripItemId);
  }

  getCancelTripInfo = async (companyId, tripItemId) => {
    this.updateCancelLoading(true);
    try {
      const res = await this.getCancellationFee(companyId, tripItemId);
      trainStore.dispatch({
        type: ACTIONS.TRIP_TRAIN.GET_CANCEL_TRIP_INFO,
        tickets: res.Tickets || [],
      });
      this.updateCancelLoading(false);

      return res;
    } catch (error) {
      return this.getCancelTripInfoFail();
    }
  };

  getRefundSumAeroExpress = (accountId, tripItemId) => Api.Trip.getRefundSumAeroExpress(accountId, tripItemId)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.LOADSUMAEROEXPRESS,
        aeroExpressRefundSum: res,
      });

      return res;
    })
    .catch(() => this.setCancellationAeroExpressFail());

  setCancellationAeroExpress = (accountId, tripItemId) => Api.Trip.setCancellationAeroExpress(accountId, tripItemId)
    .then((res) => {
      if (!res.Result) {
        return this.setCancellationAeroExpressFail();
      }

      store.dispatch({
        type: ACTIONS.TRIP.LOADSUMAEROEXPRESS,
        aeroExpressRefundSum: res,
      });

      return res;
    })
    .catch(() => this.setCancellationAeroExpressFail());

  getRefundSumAirline = (accountId, tripItemId) => Api.Trip.getRefundSumAirline(accountId, tripItemId)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.LOADSUMAIRLINE,
        airRefundSum: res,
      });

      return res;
    })
    .catch(() => this.getRefundSumAirlineFail());

  getTicketStatus = (tripItemId) => Api.Trip.getTicketStatus(tripItemId)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.LOADTICKETSTATUS,
        ticketStatus: res,
        error: false,
      });

      return res;
    })
    .catch(() => this.ticketStatusFail());

  updateTicketStatus = (tripItemId, status) => Api.Trip.updateTicketStatus({ tripItemId, status })
    .then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.UPDATETICKETSTATUS,
        ticketStatus: status,
      });

      return res;
    })
    .catch(() => this.ticketStatusFail());

  setCancellationAirlineFee = (
    accountId, tripItemId, penalties,
  ) => Api.Trip.setCancellationAirlineFee(accountId, tripItemId, { penalties })
    .then((res) => {
      store.dispatch({
        type: ACTIONS.TRIP.SETSUMAIRLINE,
        setAirRefundSum: res,
      });

      return res;
    })
    .catch(() => this.setCancellationAirlineFeeFail());

  cancelTrainItem(companyId, orderTripItemId) {
    return Api.Trip.cancelTrain(companyId, orderTripItemId);
  }

  manualTrainCancel = (companyId, tripId, body) => Api.Trip.manualTrainCancel(companyId, tripId, body);

  save = (companyId, orderTrip, orderId) => Api.Trip.save(companyId, orderTrip, orderId);

  create = (companyId, orderTrip, email) => Api.Trip.create(companyId, orderTrip, email);

  sendDocumentsAfterSaveMetadata = (...opts) => Api.Trip.sendDocumentsAfterSaveMetadata(...opts);

  getTotalTripBill = (companyId, tripId, format) => Api.Trip.getTotalTripBill(companyId, tripId, format)
    .then((res) => res)
    .catch((e) => alertDev(e, 'trip', 'getTotalTripBill'));

  getFileTotalTripBill = (guid) => Api.Trip.getFileTotalTripBill(guid);

  getTaxiVoucher = (id) => Api.Trip.getTaxiVoucher(id);

  setCancelDialogLoading = (isLoading) => store.dispatch({
    type: ACTIONS.TRIP.SET_LOADING_DIALOG,
    payload: isLoading,
  });

  setCancelVoucherInputValue = (str) => store.dispatch({
    type: ACTIONS.TRIP.CHANGE_CANCEL_VOUCHER_INPUT,
    payload: str,
  });

  getRefundResponse = async (tripItemId, serviceType) => {
    let res = null;

    switch (serviceType) {
      case SERVICETYPE.TAXI: {
        res = await Api.Trip.getRefundTaxi(tripItemId);

        break;
      }

      case SERVICETYPE.VIP_HALL: {
        res = await Api.Trip.getRefundVipHall(tripItemId);

        break;
      }
    }

    return res;
  };

  getRefundVoucher = async (tripItemId = 0, serviceType = '') => {
    this.setCancelDialogLoading(true);

    try {
      const response = await this.getRefundResponse(tripItemId, serviceType);

      const { IsAvailable, Amount } = response;

      this.setCancelDialogLoading(false);
      this.setRefundDialog(false);

      if (IsAvailable) return this.setAprooveDialog(true, Amount);

      return this.setResultVoucherDialog(true, DIALOG_RESULT_MSG[serviceType].REFUND_ERROR);
    } catch (e) {
      this.setCancelDialogLoading(false);
      this.setRefundDialog(false);
      this.setResultVoucherDialog(true, DIALOG_RESULT_MSG[serviceType].REFUND_ERROR);
    }

    return null;
  };

  getCancelResponse = async (tripItemId, serviceType) => {
    switch (serviceType) {
      case SERVICETYPE.TAXI: {
        await Api.Trip.cancelTaxiVoucher(tripItemId);

        break;
      }

      case SERVICETYPE.VIP_HALL: {
        await Api.Trip.cancelVipHallVoucher(tripItemId);

        break;
      }
    }
  };

  cancelVoucher = async (tripItemId = 0, serviceType = '') => {
    this.setCancelDialogLoading(true);
    this.setAprooveDialog(false);

    try {
      await this.getCancelResponse(tripItemId, serviceType);

      this.setResultVoucherDialog(true, DIALOG_RESULT_MSG[serviceType].SUCCESS);
      this.setCancelDialogLoading(false);
    } catch (e) {
      this.setResultVoucherDialog(true, DIALOG_RESULT_MSG[serviceType].CANCEL_ERROR);
      this.setCancelDialogLoading(false);
      this.setAprooveDialog(false);
    }
  };

  setRefundDialog = (isOpen) => store.dispatch({
    type: ACTIONS.TRIP.SET_REFUND_DIALOG,
    payload: isOpen,
  });

  setGiftVoucherDialog = (isOpen) => store.dispatch({
    type: ACTIONS.TRIP.SET_GIFT_VOUCHER_DIALOG,
    payload: isOpen,
  });

  setGiftVoucherCompanyId = (companyId) => store.dispatch({
    type: ACTIONS.TRIP.SET_GIFT_VOUCHER_COMPANY_ID,
    payload: companyId,
  });

  getGiftVoucherEmployeesInfo = async (value, query = '') => {
    await this.autocompleteEmployees(value, query);

    const { employeesList } = store.getState();

    if (!employeesList.length) {
      return store.dispatch({
        type: ACTIONS.TRIP.SET_GIFT_ERROR_GET_EMPLOYEES_LIST,
        payload: TRIPSLABELS.ERROR_GIFT_VOUCHER_COMPANY,
      });
    }

    return this.setGiftVoucherValue(FIELDS_GIFT_VOUCHER.IS_SECOND_STEP, true);
  };

  setGiftVoucherEmployee = async (employee) => {
    const employeeInfo = employee.Id ? await this.getEmployeeDataByID(employee.Id) : {};

    store.dispatch({
      type: ACTIONS.TRIP.SET_GIFT_VOUCHER_EMPLOYEE,
      payload: { employee, employeeInfo },
    });
  };

  setGiftVoucherValue = (field, value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_GIFT_VOUCHER_VALUE,
      payload: { field, value },
    });
  };

  validateGiftVoucher = () => {
    const { giftVoucherDialog } = store.getState();

    VALIDATE_FIELDS_GIFT_VOUCHER.forEach((field) => {
      this.giftVoucherFieldValidation(field, giftVoucherDialog[field]);
    });

    const { giftVoucherDialog: { validation } } = store.getState();

    return Object.keys(validation).some((key) => !!validation[key]);
  };

  saveGiftVoucher = async (moveToTrip) => {
    const { giftVoucherDialog } = store.getState();
    const {
      employeeInfo: {
        Id, MobilePhone, Name, Patronymic, Surname, Email,
      },
      startDate,
      endDate,
      classes,
      limit,
    } = giftVoucherDialog;

    if (this.validateGiftVoucher()) {
      return;
    }

    const data = {
      EmployeeId: Id,
      FullName: `${Surname} ${Name} ${Patronymic}`,
      Phone: MobilePhone,
      Email,
      Limit: limit,
      Classes: classes,
      StartDate: formatDate(startDate, FULLTIME),
      EndDate: formatDate(endDate, FULLTIME),
    };

    try {
      const res = await Api.Trip.saveGiftVoucher(data);

      store.dispatch({
        type: ACTIONS.TRIP.SET_GIFT_VOUCHER_RESULT,
        payload: res,
      });

      const {
        isSuccess, accountId, companyId, tripId,
      } = res;

      if (isSuccess) {
        moveToTrip(accountId, companyId, tripId);
      }
    } catch (err) {
      return null;
    }
  };

  setAprooveDialog = (isOpen, amount = 0) => store.dispatch({
    type: ACTIONS.TRIP.SET_APROOVE_DIALOG,
    payload: {
      isOpen,
      amount,
    },
  });

  setResultVoucherDialog = (isOpen, msg) => store.dispatch({
    type: ACTIONS.TRIP.SET_RESULT_DIALOG,
    payload: {
      isOpen,
      msg,
    },
  });

  addHistoryItem(tripItem) {
    store.dispatch({
      type: ACTIONS.TRIP.ADDHISTORYITEM,
      tripItem,
    });
  }

  addNewItem = (type, provider) => {
    switch (type) {
      case ORDERTYPE.AIR:
        return {
          OrderTripItemId: 0,
          Employees: [],
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.AIR),
          JsonData: '',
        };
      case ORDERTYPE.HOTEL:
        return {
          OrderTripItemId: 0,
          Employees: [],
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.HOTEL),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.HOTEL),
          InternalVat: lodashReplaces.cloneDeep(INTERNAL_VAT.HOTEL),
          JsonData: getHotelDefaultData(provider),
          Travellers: [],
          Meal: [],
        };
      case ORDERTYPE.GROUP_HOTEL:
        return {
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.GROUP_HOTEL),
          JsonData: getHotelDefaultData(provider),
          items: [
            {
              OrderTripItemId: 0,
              Employees: [],
              ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.HOTEL),
              PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.HOTEL),
              InternalVat: lodashReplaces.cloneDeep(INTERNAL_VAT.HOTEL),
              Travellers: [],
              Meal: [],
            },
          ],
        };
      case ORDERTYPE.APARTMENT:
        return {
          OrderTripItemId: 0,
          Employees: [],
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.APARTMENT),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.HOTEL),
          JsonData: lodashReplaces.cloneDeep(APARTMENTDEFAULTDATA),
        };
      case ORDERTYPE.TRANSFER:
        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.TRANSFER),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.TRANSFER),
          JsonData: lodashReplaces.cloneDeep(TRANSFERDEFAULTDATA),
        };
      case ORDERTYPE.CUSTOM:
        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.CUSTOM),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.CUSTOM),
          JsonData: lodashReplaces.cloneDeep(CUSTOMDEFAULTDATA),
        };
      case ORDERTYPE.EVENT: {
        return {
          OrderTripItemId: 0,
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.EVENT),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.EVENT),
          JsonData: lodashReplaces.cloneDeep(EVENT_DEFAULT_DATA),
        };
      }
      case ORDERTYPE.TRAIN:
        return {
          OrderTripItemId: 0,
          Employee: {},
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.TRAIN),
          JsonData: '',
        };
      case ORDERTYPE.VIP_HALL:
        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.VIP_HALL),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.VIP_HALL),
          JsonData: lodashReplaces.cloneDeep(VIP_HALL_DEFAULT_DATA),
        };
      case ORDERTYPE.AEROEXPRESS:
        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.AEROEXPRESS),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.AEROEXPRESS),
          JsonData: lodashReplaces.cloneDeep(VIP_HALL_DEFAULT_DATA),
        };
      case ORDERTYPE.INSURANCE:
        return {
          OrderTripItemId: 0,
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.INSURANCE),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.INSURANCE),
          JsonData: '',
        };
      case ORDERTYPE.BUS:
        return {
          OrderTripItemId: 0,
          EmployeesList: {},
          ServiceType: lodashReplaces.cloneDeep(ORDERTYPE.BUS),
          PriceDetails: lodashReplaces.cloneDeep(ORDER_PRICE.BUS),
          JsonData: lodashReplaces.cloneDeep(BUS_DEFAULT_DATA),
        };
    }

    return null;
  };

  copyItem = (copyItem) => {
    const {
      ServiceType,
      JsonData,
      PriceDetails,
      UserAnalytics,
      InternalVat = {},
      Meal,
    } = copyItem;
    const data = JSON.parse(JsonData);

    switch (ServiceType) {
      case ORDERTYPE.AIR: {
        data.map((item) => ({
          ...item,
          Passport: null,
          Passports: null,
          PNR: null,
          PNRLocator: null,
          PNRProvider: null,
          Travellers: null,
          AppliedAviacode: null,
        }));

        return {
          OrderTripItemId: 0,
          Employees: [],
          ServiceType: ORDERTYPE.AIR,
          JsonData: lodashReplaces.cloneDeep(data),
          isCopy: true,
        };
      }
      case ORDERTYPE.CUSTOM: {
        data.EmployeeIds = null;

        return {
          PriceDetails,
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: ORDERTYPE.CUSTOM,
          JsonData: lodashReplaces.cloneDeep(data),
          isCopy: true,
        };
      }
      case ORDERTYPE.EVENT: {
        return {
          PriceDetails,
          OrderTripItemId: 0,
          ServiceType: ORDERTYPE.EVENT,
          JsonData: lodashReplaces.cloneDeep(data),
          isCopy: true,
        };
      }
      case ORDERTYPE.TRANSFER: {
        data.EmployeeIds = null;

        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: ORDERTYPE.TRANSFER,
          PriceDetails,
          JsonData: lodashReplaces.cloneDeep(data),
        };
      }
      case ORDERTYPE.HOTEL: {
        data.EmployeeIds = null;

        return {
          ...copyItem.CurrencyData,
          OrderTripItemId: 0,
          Employees: [],
          ServiceType: ORDERTYPE.HOTEL,
          PriceDetails,
          JsonData: lodashReplaces.cloneDeep(data),
          InternalVat,
          Meal: MEAL_LIST_TYPE.find(({ value }) => value === Meal.Category).list,
        };
      }
      case ORDERTYPE.TRAIN: {
        data.EmployeeIds = null;

        return {
          OrderTripItemId: 0,
          Employee: {},
          ServiceType: ORDERTYPE.TRAIN,
          PriceDetails,
          UserAnalytics,
          JsonData: lodashReplaces.cloneDeep(data),
        };
      }
      case ORDERTYPE.VIP_HALL: {
        data.EmployeeIds = null;

        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: ORDERTYPE.VIP_HALL,
          PriceDetails,
          JsonData: lodashReplaces.cloneDeep(data),
        };
      }
      case ORDERTYPE.AEROEXPRESS: {
        data.EmployeeIds = null;

        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: ORDERTYPE.AEROEXPRESS,
          PriceDetails,
          JsonData: lodashReplaces.cloneDeep(data),
        };
      }
      case ORDERTYPE.AIR_ADDITIONAL_SERVICE: {
        const { stateToSave } = copyItem;

        return { ...stateToSave };
      }
      case ORDERTYPE.BUS: {
        data.EmployeeIds = null;

        return {
          OrderTripItemId: 0,
          Employees: {},
          ServiceType: ORDERTYPE.BUS,
          PriceDetails,
          JsonData: lodashReplaces.cloneDeep(data),
        };
      }
    }

    return null;
  };

  editItem = (item) => {
    switch (item.ServiceType) {
      case ORDERTYPE.AIR:
      case ORDERTYPE.CUSTOM:
      case ORDERTYPE.EVENT:
      case ORDERTYPE.TRANSFER:
      case ORDERTYPE.TRAIN:
      case ORDERTYPE.VIP_HALL:
      case ORDERTYPE.AEROEXPRESS:
      case ORDERTYPE.AIR_ADDITIONAL_SERVICE:
      case ORDERTYPE.HOTEL:
      case ORDERTYPE.BUS:
      case ORDERTYPE.INSURANCE: {
        return {
          ...item,
          isEdit: true,
        };
      }

      default:
        return null;
    }
  };

  getHotelDetails = (id) => Api.Trip.getHotelDetails(id);

  set(trip) {
    store.dispatch({
      type: ACTIONS.TRIP.UPDATE,
      trip,
    });
  }

  setTripAnalytics = (analytics = []) => {
    store.dispatch({
      type: ACTIONS.TRIP.SETANALYTICS,
      payload: analytics,
    });
  };

  get() {
    return store.getState();
  }

  getServiceType() {
    return SERVICETYPE;
  }

  getHotelProviderName() {
    return HOTELPROVIDERNAME;
  }

  getTransferCarClass() {
    return TRANSFERCARCLASS;
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

  clearCacheByTripId(companyId, tripId) {
    return Api.Trip.clearCacheByTripId(companyId, tripId);
  }

  autocompleteTransfer(value, ids) {
    return value ? autocompleteDebounce({ query: value, aId: ids }) : null;
  }

  autocompleteHotel = (value, ids) => (value ? autocompleteHotelDebounce({ query: value, aId: ids }) : null);

  autocompleteCompany = async (value, ids) => {
    if (!value) return [];

    try {
      return await autocompleteCompanyDebounce({ query: value, aId: ids });
    } catch (e) {
      return [];
    }
  };

  autocompleteEmployees = async (companyId, value) => {
    const body = { Query: value };

    store.dispatch({
      type: ACTIONS.TRIP.LOADING_AUTOCOMPLETE_EMPLOYEES,
      payload: true,
    });

    try {
      const employeesList = await autocompleteEmployeesDebounce(companyId, body);

      store.dispatch({
        type: ACTIONS.TRIP.AUTOCOMPLETE_EMPLOYEES,
        payload: { employeesList },
      });
      store.dispatch({
        type: ACTIONS.TRIP.LOADING_AUTOCOMPLETE_EMPLOYEES,
        payload: false,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.TRIP.AUTOCOMPLETE_EMPLOYEES,
        payload: { employeesList: [] },
      });
      store.dispatch({
        type: ACTIONS.TRIP.LOADING_AUTOCOMPLETE_EMPLOYEES,
        payload: false,
      });
    }
  };

  autocompleteRegion = debounce(async (value) => {
    try {
      const res = await Api.Trip.autocompleteRegion(value);

      return res;
    } catch (e) {
      return [];
    }
  }, DEBOUNCE_TIME);

  autocompleteCountry = debounce(async (str) => {
    try {
      return Api.Trip.autocompleteCountry(str);
    } catch (e) {
      return [];
    }
  }, DEBOUNCE_TIME);

  getTravellineRates = (id) => Api.Trip.travellineProviderRates(id);

  getContractHotel = (id) => Api.Trip.getContractHotel(id);

  getTravellineHotelConnected = (id) => Api.Trip.travellineHotelConnected(id);

  getHotelCompare = (id) => Api.Trip.hotelCompare(id);

  getContractConnectCompareHotel = async (Id) => {
    try {
      const [
        contract,
        connected,
        compared,
      ] = await Promise.all([
        this.getContractHotel(Id),
        this.getTravellineHotelConnected([Id]),
        this.getHotelCompare(Id),
      ]);

      return {
        contract,
        connected,
        compared,
      };
    } catch (err) {
      return {
        contract: 0,
        connected: 0,
        compared: 0,
      };
    }
  };

  getHotelOrderInfo = async (orderId, providerName, isCustom = false) => {
    try {
      return Api.Trip.getOrderInfoTravelLine(orderId, providerName, isCustom);
    } catch (e) {
      return Promise.reject(null);
    }
  };

  getProviders = (value, service) => autocompleteProvidersDebounce(value, service);

  getAllProviders = (service) => Api.Trip.selectAndInputAllProviders(service);

  getTypesCustom = () => Api.Trip.autocompleteTypesCustom();

  getEmployeeDataByID = async (id) => {
    try {
      const res = await Api.Trip.getEmployeeDataByID(id);

      return res;
    } catch (e) {
      return Promise.reject(null);
    }
  };

  tripFieldValidation(field, value) {
    return this.validator.tripName.isValid(field, value);
  }

  customTripFieldValidation = (field, value) => this.validator.custom.isValid(field, value);

  eventTripFieldValidation = (field, value) => this.validator.event.isValid(field, value);

  trainTripFieldValidation(field, value, isPartEdit) {
    return this.validator.train.isValid(field, value, isPartEdit);
  }

  hotelTripFieldValidation = (field, value) => this.validator.hotel.isValid(field, value);

  transferTripFieldValidation = (field, value) => this.validator.transfer.isValid(field, value);

  vipHallTripFieldValidation = (field, value) => this.validator.vipHall.isValid(field, value);

  aeroexpressTripFieldValidation = (field, value) => this.validator.aeroexpress.isValid(field, value);

  taxiTripFieldValidation = (field, value) => this.validator.taxi.isValid(field, value);

  giftVoucherFieldValidation = (field, value) => {
    const res = this.validator.giftVoucher.isValid(field, value);

    store.dispatch({
      type: ACTIONS.TRIP.SET_VALIDATION_GIFT_VOUCHER,
      payload: { field, res },
    });
  };

  insuranceTripFieldValidation = (field, value, idx) => {
    const res = this.validator.insurance.isValid(field, value);

    store.dispatch({
      type: ACTIONS.TRIP.SET_VALIDATION_INSURANCE,
      payload: {
        field, res, idx,
      },
    });
  };

  busTripFieldValidation = (field, value) => this.validator.bus.isValid(field, value);

  getAirTripInfo = (provider, ticketNumber, pnr, surname, lastName, officeId, salePoint) => Api.Trip.getTripInfo({
    Provider: provider,
    TicketNumber: ticketNumber,
    PNR: pnr,
    LastNameRu: surname,
    LastNameEn: lastName,
    OfficeId: officeId,
    SalePoint: salePoint,
  });

  countTrip() {
    return Api.Trip.countTrips();
  }

  deleteMetadata(tripId, tripItemId) {
    return Api.Trip.deleteMetadata(tripId, tripItemId);
  }

  downloadVoucher(companyId, tripItemId, locale = LOCALE.RU) {
    return Api.Trip.downloadVoucher(companyId, tripItemId, locale);
  }

  downloadVoucherGroupLiving = async (companyId, tripItemId) => {
    try {
      await Api.Trip.downloadVoucherGroupLiving(companyId, tripItemId);
    } catch (err) {
      Promise.reject(null);
    }
  };

  downloadBreakfastVoucher = (companyId, tripItemId) => {
    const params = {
      HotelVoucherOptions: {
        HideRoomType: true,
        HideBreakfast: true,
      },
    };

    return Api.Trip.downloadBreakfastVoucher(companyId, tripItemId, params);
  };

  downloadFlightCertificate = async (airItemId, signatureType, isFreeFlightCertificate) => {
    try {
      if (isFreeFlightCertificate) {
        await Api.Trip.getFreeFlightCertificate(airItemId, signatureType);
      } else {
        await Api.Trip.getFlightCertificate(airItemId, signatureType);
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  getStatusFlightCertificate = async (airItemId) => {
    try {
      const status = await Api.Trip.getStatusFlightCertificate(airItemId);

      return status;
    } catch (error) {
      return FLIGHT_CERTIFICATE_STATUS_TYPES.ERROR;
    }
  };

  bookFlightCertificate = async (airItemId) => {
    try {
      const { Success } = await Api.Trip.bookFlightCertificate(airItemId);

      return Success;
    } catch (error) {
      return false;
    }
  };

  downloadServiceForm(accountId, tripItemId, serviceId) {
    return Api.Trip.downloadServiceForm(accountId, tripItemId, serviceId);
  }

  downloadOldVoucher = (accountId, tripItemVersionId) => Api.Trip.downloadOldVoucher(accountId, tripItemVersionId);

  changeTrainVoucher = (
    tripItemId, ProviderName,
  ) => Api.Trip.changeTrainVoucher({ Provider: ProviderName, TripItemId: tripItemId });

  changeAeroExpressVoucher = async (tripItemId) => {
    try {
      await Api.Trip.changeAeroexpressVoucher(tripItemId);
    } catch (error) {
      alert(error.body.Error);
    }
  };

  setAeroLoading = (value) => store.dispatch({
    type: ACTIONS.TRIP.SET_AERO_LOADING,
    payload: value,
  });

  setErrorReqFile = (value) => store.dispatch({
    type: ACTIONS.TRIP.SET_ERROR_REQ_FILE,
    payload: value,
  });

  getAeroexpressVoucher = async (data) => {
    this.setAeroLoading(true);
    try {
      const response = await Api.Trip.getAeroexpressVoucher(data);

      this.setAeroLoading(false);

      return response;
    } catch (err) {
      this.setErrorReqFile(true);
      this.setAeroLoading(false);

      return null;
    }
  };

  postAeroexpressVoucher = async (serviceType, file) => {
    try {
      const response = await Api.Trip.postAeroexpressVoucher(serviceType, file);
      store.dispatch({
        type: ACTIONS.TRIP.GET_TICKET_FIELD_ID,
        payload: response,
      });

      return response;
    } catch (err) {
      return null;
    }
  };

  сhangeСostСenter = async (objChangeCC, companyId, tripId) => {
    try {
      await Api.Trip.сhangeСostСenter(objChangeCC);
    } catch (error) {
      return false;
    } finally {
      await this.updateTrip(companyId, tripId);
      this.getStatusEditLegalEntity(objChangeCC.tripItemId);
    }
  };

  addTripToVoucher = (
    tripItemVersionId, voucherId, newTripId,
  ) => Api.Trip.addTripToVoucher(tripItemVersionId, voucherId, newTripId);

  check = (tripId, orderIds) => Api.Trip.check(tripId, orderIds);

  checkTrips = async (tripIds) => {
    try {
      return await Api.Trip.checkTrips(tripIds);
    } catch (error) {
      return null;
    }
  };

  moveTrips = async (tripIds, companyId) => {
    try {
      return await Api.Trip.moveTrips(tripIds, companyId);
    } catch (error) {
      return null;
    }
  };

  checkCloseDocsChangeVersion = async (tripId, orderIds) => {
    try {
      const response = await Api.Trip.check(tripId, orderIds);

      return response;
    } catch (err) {
      return null;
    }
  };

  moveTrip(tripId, companyId) {
    return Api.Trip.moveTrip(tripId, companyId);
  }

  moveOrders(tripId, companyId, orderIds) {
    return Api.Trip.moveOrders(tripId, companyId, orderIds);
  }

  moveOperations(tripId, voucherId, operationIds) {
    return Api.Trip.moveOperations(tripId, voucherId, operationIds);
  }

  moveActualVersion = (
    tripId, companyId, orderId, tripItemVersionId,
  ) => Api.Trip.moveActualVersion(tripId, companyId, orderId, tripItemVersionId);

  moveVouchers = (data) => Api.Trip.moveVouchers(data);

  sendVouchers(accountId, tripId, email) {
    return Api.Trip.sendVouchers(accountId, tripId, email);
  }

  sendVoucher(companyId, itemId, emails, locale) {
    return Api.Trip.sendVoucher(companyId, itemId, emails, locale);
  }

  sendOldVoucher = (companyId, itemId, emails) => Api.Trip.sendOldVoucher(companyId, itemId, emails);

  sendServiceForm = (
    accountId, tripItemId, serviceId, email,
  ) => Api.Trip.sendServiceForm(accountId, tripItemId, serviceId, email);

  sendInvoice(accountId, tripId, email) {
    return Api.Trip.sendInvoice(accountId, tripId, email);
  }

  getOrderHotelInfo = (provider, orderId) => Api.Trip.getOrderHotelInfo(provider, orderId.trim());

  getOrderTrainInfo = async (accountId, orderId, saleOrderId, provider) => {
    const data = {
      Provider: provider,
      OrderId: orderId.trim(),
      AccountId: accountId,
      SaleOrderId: saleOrderId.trim(),
    };

    const {
      VoucherData: {
        OrderId, SaleOrderId, ProviderName, Employee, TicketId, PassportId,
      },
      Blank: { FileId, FileName },
      Route,
      Tariff,
      CarrierDetails,
      PriceDetails,
    } = await Api.Trip.getOrderTrainInfo(data);
    const fileBlob = await Api.Trip.getFile(FileId);

    const file = new File([fileBlob], FileName);
    this.updateFileWithoutReq(FileId, file);

    const { JsonData } = this.getTrainData();
    JsonData.CarrierDetails = { ...JsonData.CarrierDetails, ...CarrierDetails };
    JsonData.PriceDetails = { ...JsonData.PriceDetails, ...PriceDetails };
    JsonData.Route = makeTrainDatesToMoment({ ...JsonData.Route, ...Route }, false);
    JsonData.TicketId = TicketId;
    JsonData.OrderId = orderId;
    JsonData.SaleOrderId = SaleOrderId;
    JsonData.ProviderName = ProviderName;
    JsonData.PassportId = PassportId;
    const newJsonData = { ...JsonData, TrainPricing: Tariff };
    this.changeField(newJsonData, FIELDS.JSON_DATA);

    return {
      ServiceType: SERVICETYPE.TRAIN,
      OrderTripItemId: OrderId,
      Employee,
    };
  };

  getRegion = async (id) => {
    try {
      return Api.Trip.getRegion(id);
    } catch (err) {
      return null;
    }
  };

  getOrderTransferInfo = (orderId) => Api.Trip.getOrderTransferInfo(Number(orderId.trim()));

  sendTripAnalytic = async (accountId, eventType, eventService, dateObject) => {
    const TripData = {
      Event: eventType,
      Service: eventService,
      AccountId: accountId,
      EventDate: formatDate(dateObject, FULLTIME),
    };
    try {
      return Api.Trip.sendTripRequestAnalytic([TripData]);
    } catch (error) {
      return error;
    }
  };

  sendVoidingInfo = (accountId, tripId, tripItemId) => Api.Trip.sendVoidingInfo(accountId, tripId, tripItemId);

  getVatAmount = (data) => Api.Trip.getVatAmount(data);

  getValidation = (data) => Api.Trip.getValidation(data);

  addVatVersions = (data) => Api.Trip.addVatVersions(data);

  setWaitPreloader = (isPreloader) => store.dispatch({
    type: ACTIONS.TRIP.SET_WAIT_PRELOADER,
    payload: isPreloader,
  });

  sendVersionTime = async (data) => {
    const eventDate = { EventDate: data.EventDate };

    try {
      return Api.Trip.sendVersionTime(data, eventDate);
    } catch (err) {
      return {
        Message: 'Произошла ошибка при получении данных',
        Success: false,
      };
    }
  };

  saveTripVersionsCount = (count) => store.dispatch({
    type: ACTIONS.TRIP.SAVE_TRIP_VERSIONS_COUNT,
    payload: count,
  });

  currentAccountId = () => store.getState().currentTrip.AccountId;

  findTripById = async (id) => {
    this.updateDialogLoading(LOADING_STATUS.LOADING);
    try {
      const resp = await autocompleteTripToMergeDebounce(id, this.currentAccountId());
      store.dispatch({
        type: ACTIONS.TRIP.SET_TRIP_TO_MERGE,
        payload: resp,
      });
      this.updateDialogLoading(resp ? LOADING_STATUS.LOADED : LOADING_STATUS.ERROR);
    } catch (e) {
      this.updateDialogLoading(LOADING_STATUS.ERROR);
    }
  };

  mergeTrips = async () => {
    this.updateMergeLoading(LOADING_STATUS.LOADING);
    const state = this.store.getState();
    try {
      await Api.Trip.postMergeTrips(
        state.currentTrip.TripId,
        state.tripToMerge,
      );
      store.dispatch({ type: ACTIONS.TRIP.SET_TRIP_TO_MERGE, payload: null });
      this.updateMergeLoading(LOADING_STATUS.LOADED);
    } catch (e) {
      this.updateMergeLoading(LOADING_STATUS.ERROR);
    }
  };

  clearMergeTripsStatuses = () => {
    this.updateDialogLoading(LOADING_STATUS.NEVER);
    this.updateMergeLoading(LOADING_STATUS.NEVER);
  };

  convertHotelMetadata(data, provider) {
    const {
      CheckInDate,
      CheckOutDate,
      TravallersCount,
      Address,
      CheckInTime,
      CheckOutTime,
      City,
      HotelName,
      Phone,
      Stars,
      Date,
      OrderId,
      RoomGroupId,
      CancellationDate,
      IncludedMeal,
      Price,
      VAT,
      Travellers,
      MF,
      CountryCode,
      RackRate,
      Meal,
    } = data;

    const isAanda = HOTEL_PROVIDER_VALUE.aanda === provider;

    const getAandaData = () => {
      const {
        NightsCount,
        PriceDetails,
        CancellationPenalties,
        NightsPrice,
        AdditionalInfo,
        RoomName,
        EarlyCheckInAndLateCheckOut: { CustomCheckInDate },
        EarlyCheckInAndLateCheckOut: { CustomCheckOutDate },
        ReportAmount,
      } = data;

      return {
        NightsCount,
        PriceDetails,
        CancellationPenalties,
        NightsPrice,
        AdditionalInfo,
        RoomName,
        CustomCheckInDate,
        CustomCheckOutDate,
        ReportAmount,
      };
    };

    const jsonData = {
      CheckinDate: CheckInDate,
      CheckoutDate: CheckOutDate,
      EmployeeIds: null,
      GuestCount: TravallersCount,
      Hotel: {
        Address,
        CheckinTime: CheckInTime,
        CheckoutTime: CheckOutTime,
        City,
        Name: HotelName,
        Phone,
        Stars,
        ClassificatorId: data.ClassificatorId || null,
        RegionId: data.RegionId || null,
      },
      Price: 0,
      ReservationDate: Date,
      ReservationNumber: OrderId,
      Room: {
        Category: RoomGroupId,
        FreeCancellation: CancellationDate,
      },
      ProviderName: provider,
      Breakfast: IncludedMeal,
      ServiceType: SERVICETYPE.HOTEL,
    };

    return {
      OrderTripItemId: 0,
      Employees: [],
      ServiceType: 'Hotel',
      PriceDetails: {
        Base: Price,
        commission: 0,
        hasVat: VAT,
        EarlyCheckIn: isAanda ? data.EarlyCheckInAndLateCheckOut.EarlyCheckIn : 0,
        LateCheckOut: isAanda ? data.EarlyCheckInAndLateCheckOut.LateCheckOut : 0,
      },
      JsonData: isAanda
        ? lodashReplaces.cloneDeep({ ...jsonData, ...getAandaData() })
        : lodashReplaces.cloneDeep(jsonData),
      Travellers,
      InternalVat: data.InternalVat,
      MF,
      MFInCurrency: MF,
      Inclusive: RackRate,
      CountryCode,
      CancellationPenalties: provider === HOTEL_PROVIDER_VALUE.expedia
        ? data.CancellationPenalties.map((item) => ({
          ...item,
          From: momentObject(item.From),
          Base: 0,
          ProviderPrice: item.Base,
        }))
        : [],
      isFromOrderByNumber: true,
      Meal: MEAL_LIST_TYPE.find(({ value }) => value === Meal.Category).list,
    };
  }

  getApplicationEvents = (data) => Api.Trip.getApplicationEvents(data).then((res) => {
    store.dispatch({
      type: ACTIONS.TRIP.EVENTS,
      payload: res.map(({ Name, Id }) => ({ label: Name, value: Id })),
    });
  });

  getApplicationFullEvents = (data) => Api.Trip.getApplicationEvents(data).then((res) => {
    store.dispatch({
      type: ACTIONS.TRIP.FULL_EVENTS,
      payload: res,
    });
  });

  createApplicationEvent = (data) => {
    const {
      creationDate,
      creationTime,
      startDate,
      endDate,
      ...rest
    } = data;

    const createdDate = momentSetHoursAndMinutes(
      momentObject(creationDate),
      creationTime.hour(),
      creationTime.minute(),
    ).format(FULLTIME);

    const newData = {
      ...rest,
      createdDate,
      startDate: startDate ? formatDate(startDate, FULLTIME) : null,
      endDate: startDate ? formatDate(endDate, FULLTIME) : null,
    };

    return Api.Trip.createApplicationEvent(newData);
  };

  cancelApplicationEvents = async (id) => {
    await Api.Trip.cancelApplicationEvents(id);

    await store.dispatch({
      type: ACTIONS.TRIP.CANCEL_EVENT,
      payload: id,
    });
  };

  deleteApplicationEvent = async (id) => {
    await Api.Trip.deleteApplicationEvent(id);

    await store.dispatch({
      type: ACTIONS.TRIP.DELETE_EVENT,
      payload: id,
    });
  };

  sortingApplicationEvent = (type) => {
    store.dispatch({
      type: ACTIONS.TRIP.SORTING_EVENTS,
      payload: type,
    });
  };

  setApplicationEvents = (data) => {
    Api.Trip.setApplicationEvents(data);

    store.dispatch({
      type: ACTIONS.TRIP.EVENT,
      payload: data,
    });
  };

  downloadTripApprovalReport = async (tripId) => {
    try {
      await Api.Trip.downloadTripApprovalReport(tripId);
    } catch (error) {
      if (error && error.statusCode === 400 && error.body.error && error.body.error.code === LABELS.NOT_FOUND) {
        return null;
      }
    }
    throw error;
  };

  unpinApplicationEvents = async (id) => {
    await Api.Trip.unpinApplicationEvents(id);
  };

  getEstimate = async (id) => {
    try {
      const res = await Api.Trip.getEstimate(id);

      store.dispatch({
        type: ACTIONS.TRIP.GET_ESTIMATE,
        payload: res,
      });
    } catch (e) {
      store.dispatch({ type: ACTIONS.TRIP.ERROR_ESTIMATE });
    }
  };

  setEstimate = async (id, data) => {
    try {
      await Api.Trip.setEstimate(id, { estimate: data });

      store.dispatch({
        type: ACTIONS.TRIP.SET_ESTIMATE,
        payload: data,
      });
    } catch (e) {
      store.dispatch({ type: ACTIONS.TRIP.ERROR_ESTIMATE });
    }
  };

  getPricesBookingMarkup = async (data) => {
    try {
      const res = await Api.Trip.getPricesBookingMarkup(data);

      return res;
    } catch (err) {
      return null;
    }
  };

  getOperationsByVersionIds = async (versionIds) => {
    try {
      return Api.Trip.getOperationsByVersionIds(versionIds);
    } catch (err) {
      return [];
    }
  };

  updatePrice = async (versionId, data) => {
    try {
      return Api.Trip.updatePrice(versionId, data);
    } catch (err) {
      return err;
    }
  };

  priceTripFieldValidation = (field, value) => this.validator.price.isValid(field, value);

  getEmployeesByIds = async (ids) => {
    try {
      const res = await Api.Trip.getEmployeesByIds(ids);

      return res;
    } catch (err) {
      return [];
    }
  };

  selectAllItemsInsurance = () => {
    store.dispatch({ type: ACTIONS.TRIP.SELECT_ALL_ITEMS_INSURANCE });
  };

  deleteAllSelectedItemsInsurance = () => {
    store.dispatch({ type: ACTIONS.TRIP.DELETE_ALL_SELECTED_ITEMS_INSURANCE });
  };

  getItemsForInsurance = async (id) => {
    const res = await Api.Trip.getInsuranceCalculate(id);

    store.dispatch({
      type: ACTIONS.TRIP.ITEMS_INSURANCE,
      payload: { items: res.InsuranceServices },
    });
    store.dispatch({
      type: ACTIONS.TRIP.SET_RATES,
      payload: { rates: res.InsuranceRatePrice },
    });

    this.selectAllItemsInsurance();
  };

  setProviderInsurance = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_PROVIDER_INSURANCE,
      payload: value,
    });
  };

  recalculateRates = async () => {
    const { insurance: { totalPrice } } = this.get();

    const res = await Api.Trip.recalculateRatesInsurance({ TotalPrice: totalPrice });

    store.dispatch({
      type: ACTIONS.TRIP.SET_RATES,
      payload: { rates: res },
    });
  };

  selectInsuranceItem = (service) => {
    store.dispatch({
      type: ACTIONS.TRIP.SELECT_ITEM_INSURANCE,
      payload: { service },
    });
  };

  selectRateInsurance = (name, price) => {
    store.dispatch({
      type: ACTIONS.TRIP.SELECT_RATE_INSURANCE,
      payload: { name, price },
    });
  };

  changePriceInsurance = (field, value) => {
    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_PRICE_INSURANCE,
      payload: { field, value },
    });
  };

  selectCompanyId = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SELECT_COMPANY_ID,
      payload: value,
    });
  };

  prepareDataForSave = async (guid, isEdit = false) => {
    const {
      insurance: {
        items,
        selectedItems,
        selectedRate,
        orderTripItemId,
        orderId,
        description,
        status,
        employees,
        validation,
        provider,
        price: { Base, Commission },
        companyId,
      },
    } = this.get();

    const validItems = isEdit ? validation.Items.find((item) => !item.length) : true;
    const isNotValid = Object
      .keys(validation)
      .find((key) => key !== FIELDS.ITEMS && !!validation[key]);

    if (!isNotValid && validItems) {
      const { checkinDate, checkoutDate } = prepareDatesInsurance(selectedItems);

      let res = [];
      let uniqueEmployeeIds = [];

      if (!isEdit) {
        const employeesIds = selectedItems.reduce((pre, { Employees }) => ([...pre, ...Employees]), []);
        uniqueEmployeeIds = [...new Set(employeesIds)];

        res = await this.getEmployeesByIds(uniqueEmployeeIds);
      } else {
        const employeesIds = employees.map(({ Id }) => Id);
        uniqueEmployeeIds = [...new Set(employeesIds)];
      }

      const jsonData = JSON.stringify({
        OrderId: orderId,
        Rate: selectedRate,
        InsuranceServices: isEdit ? items : selectedItems,
        CheckinDate: checkinDate,
        CheckoutDate: checkoutDate,
        EmployeeIds: uniqueEmployeeIds,
        ServiceType: SERVICETYPE.INSURANCE,
        ProviderName: provider,
        ReservationDate: getMoment(),
      });

      const data = {
        Description: description,
        ProviderName: provider,
        ServiceType: SERVICETYPE.INSURANCE,
        PriceDetails: { Base, Commission },
        EmployeesList: isEdit ? employees : res,

        OrderTripId: 0,
        OrderTripItemId: orderTripItemId,
        CompanyId: parseInt(companyId, 10),

        Status: status,
        Guid: guid,

        JsonData: jsonData,
      };

      return data;
    }

    return null;
  };

  setEditedInsurance = (item) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_EDITED_INSURANCE,
      payload: item,
    });
  };

  setDescriptionInsurance = (description) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_DESCRIPTION_INSURANCE,
      payload: { description },
    });
  };

  resetInsurance = () => {
    store.dispatch({ type: ACTIONS.TRIP.RESET_INSURANCE });
  };

  changeDataServiceInsurance = (field, value, idx) => {
    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_DATA_SERVICE_INSURANCE,
      payload: {
        field, value, idx,
      },
    });
  };

  changeEditedServiceInsurance = (field, value, idx) => {
    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_EDITED_SERVICE_INSURANCE,
      payload: {
        field, value, idx,
      },
    });
  };

  cancelCurrencyAmount = async (data) => {
    try {
      const res = await Api.Trip.cancelCurrencyAmount(data);

      return res;
    } catch (err) {
      return { OrderState: STATUSES_ERROR_CURRENCY.CANCELLED };
    }
  };

  autocompleteBusStation = async (value) => {
    try {
      return await autocompleteBusStationDebounce({ query: value });
    } catch (e) {
      return [];
    }
  };

  changeFieldGroupBooking = (field, value) => {
    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_FIELD_GROUP_BOOKING,
      payload: { field, value },
    });
  };

  setValidationGroupHotel = (field, value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_VALIDATION_GROUP_HOTEL,
      payload: { field, value },
    });
  };

  resetHotelProviderData = () => {
    store.dispatch({ type: ACTIONS.TRIP.RESET_HOTEL_PROVIDER_DATA });
  };

  resetMainHotelFields = () => {
    store.dispatch({ type: ACTIONS.TRIP.RESET_MAIN_HOTEL_FIELDS });
  };

  changeJsonInputGroupHotel = (field, value, JsonData) => {
    let newValue = value;
    const newJsonData = { ...JsonData };

    const part = field.split('.');

    if (typeof value === 'number') {
      newValue = parseFloat(value.toFixed(2));
    }

    if (part.length === 1) {
      newJsonData[field] = newValue;
    } else {
      newJsonData[part[0]][part[1]] = newValue;
    }

    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_JSON_INPUT_GROUP_HOTEL,
      payload: newJsonData,
    });
  };

  changeJsonGroupBooking = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_JSON_INPUT_GROUP_HOTEL,
      payload: value,
    });
  };

  changeDateGroupHotel = (field, value, JsonData) => {
    const isTodayOrYesterday = [FIELDS.RESERVATIONTODAY, FIELDS.RESERVATIONYESTERDAY].includes(field);
    const jsonData = this.getNewJsonData(field, JsonData, value, isTodayOrYesterday);

    store.dispatch({
      type: ACTIONS.TRIP.CHANGE_JSON_INPUT_GROUP_HOTEL,
      payload: jsonData,
    });

    if (isTodayOrYesterday) {
      const result = this.hotelTripFieldValidation(FIELDS.RESERVATIONDATE, jsonData.ReservationDate);
      this.setValidationGroupHotel(FIELDS.RESERVATIONDATE, result);
    }
  };

  getNewJsonData = (field, newJsonData, value, isTodayOrYesterday) => {
    const part = field.split('.');

    if (isTodayOrYesterday) {
      return {
        ...newJsonData,
        ReservationDate: field === FIELDS.RESERVATIONTODAY ? getMoment() : subDate(1),
      };
    }

    if (!isTodayOrYesterday) {
      if (part.length === 1) {
        return {
          ...newJsonData,
          [field]: value,
        };
      }

      return {
        ...newJsonData,
        [part[0]]: {
          ...[part[0]],
          [part[1]]: value,
        },
      };
    }
  };

  autocompleteGroupBookingDetails = async ({ Id }) => {
    const res = await this.getHotelDetails(Id);
    res.RegionId = getRegionId(res.RegionId);

    store.dispatch({
      type: ACTIONS.TRIP.SET_GROUP_BOOKING_HOTEL_DETAILS,
      payload: res,
    });
  };

  setGroupHotelName = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_GROUP_HOTEL_NAME,
      payload: value,
    });

    if (value) {
      this.autocompleteHotel(value).then((res) => {
        const resFilter = res ? res.filter(({ IsRegion }) => !IsRegion) : [];

        store.dispatch({
          type: ACTIONS.TRIP.SET_GROUP_HOTEL_NAME_SUGGEST,
          payload: resFilter,
        });
      });
    }
  };

  setContractConnectCompareGroupBooking = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_CONTRACT_CONNECT_COMPARE_GROUP_BOOKING,
      payload: value,
    });
  };

  setRatesGroupBooking = (value) => {
    store.dispatch({
      type: ACTIONS.TRIP.SET_RATES_GROUP_BOOKING,
      payload: value,
    });
  };
}

export default TripService;
